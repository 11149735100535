import image from "../assets/svg/about/about.jpg";

export const aboutData = {
  title: "Who am I?",
  description1: "Hi👋 I am Utsav.",
  description2:
    "I am an experienced Software Engineer with over 5 years of expertise in Flutter, Nodejs and Golang. Throughout my career, I have successfully delivered a wide range of projects, leveraging the power of Flutter's cross-platform capabilities and Golang's robustness. I've led teams and managed large-scale internal projects while delivering a diverse array of successful endeavors.",
  description3:
    "With a strong focus on quality and efficiency, I have developed mobile applications, server-side systems, and APIs that have met the highest industry standards. I thrive in dynamic environments and enjoy collaborating with teams to tackle complex challenges. I am enthusiastic about applying my skills and knowledge to drive innovation and create exceptional software solutions.",
  description4:
    "I am enthusiastic about applying my skills and knowledge to drive innovation and create exceptional software solutions.",
  image: image,
};
