export const experienceData = [
  {
    id: 1,
    company: "Qubited Corporations",
    jobtitle: "Senior Software Engineer",
    startYear: "September 2023 ",
    endYear: "  Present",
    description: [
      "Team:",
      "- Providing technical leadership and guidance to the development team.",
      "- Mentoring junior team members and fostering their growth.",
      "- Conducting code reviews and providing constructive feedback.",
      "- Identifying and addressing technical debt and code quality issues.",
      "- Taking ownership of project deliverables and meeting deadlines.",
      "Handling Workflow:",
      "- Participating in sprint planning, estimation, and retrospective meetings.",
      "- Ensuring adherence to coding standards and best practices.",
      "- Contributing to the continuous improvement of development processes and practices.",
      "- Communicating effectively with cross-functional teams and stakeholders.",
      "Other Responsibilities:",
      "- Leading the design and implementation of software solutions.",
      "- Collaborating with stakeholders to gather and analyze requirements.",
      "- Architecting scalable and maintainable software systems.",
      "- Writing clean, efficient, and well-documented code.",
      "- Researching and evaluating new technologies and tools.",
      "- Troubleshooting complex technical issues and providing timely resolutions.",
      "- Keeping abreast of industry trends and developments in software engineering.",
    ],
  },
  {
    id: 1,
    company: "Qubited Corporations",
    jobtitle: "Software Engineer",
    startYear: "December 2021 ",
    endYear: " September 2023",
    description: [
      "React Native:",
      "- Proficient in Redux or similar state management.",
      "- Familiarity with Xcode and Android Studio.",
      "- Testing: unit, integration, end-to-end (enzyme, jest).",
      "- RESTful API integration, JSON handling.",
      "- GraphQL integration, optimization.",
      "- App deployment to App Store, Google Play.",
      "- Agile methodologies familiarity.",
      "Flutter:",
      "- Expert in Flutter, Dart language.",
      "- Bloc, Provider, Riverpod for state.",
      "- Clean Architecture, MVVM, Repository.",
      "- Troubleshooting and debugging skills.",
      "- Design principles, patterns, best practices.",
      "- Thorough testing: unit, widget, integration.",
      "- Integration testing: appium, browserstack and patrol.",
      "- Cross-platform app development.",
      "- RESTful API, GraphQL integration.",
      "- Firebase, Crashlytics implementation.",
      "- Third-party package integration.",
      "Integrations:",
      "- Offline storage, isolates for performance.",
      "- Cloud message APIs, push notifications.",
      "- Scalable, reliable stack design.",
      "- App performance optimization.",
      "- Git, version control proficiency.",
      "- GitHub workflows for collaboration.",
    ],
  },
  // {
  //   id: 2,
  //   company: "Open for Freelancing",
  //   jobtitle: "Mobile Application Developer",
  //   startYear: "March 2020",
  //   endYear: "  Present",
  // },
  {
    id: 3,
    jobtitle: "Associate Software Engineer",
    company: "i-Nepal Pvt. Ltd.",
    startYear: "March 2020 ",
    endYear: "  July 2021",
    description: [
      "Flutter Specific",
      "- Write scalable and maintainable code ",
      "- State management using Provider",
      "- Integrating various plugins like google maps, ffmpeg, payment gateways, video conference using Agora sdk.",
      "- Testing application on various platform",
      "- Git, version control proficiency.",
      "- GitHub workflows for collaboration.",
      "Django Specific",
      "- Design backend system for mid level projects",
      "- Developing apis using Django rest framework",
      "- Serialization ",
      "- ORM",
    ],
  },
  {
    id: 4,
    company: "An4Soft Pvt. Ltd.",
    jobtitle: "Mobile Application Developer",
    startYear: "March 2019  ",
    endYear: "  March 2020",
    description: [
      "Flutter Specific Works",
      "- Responsive UI design",
      "- Connecting flutter app to firebase",
      "- Connecting Flutter app with Backend using HTTP",
      "- Using device sensors like Camera, fingerprint, etc.",
      "- Git, version control proficiency.",
      "- GitHub workflows for collaboration.",
    ],
  },
];
