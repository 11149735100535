/* eslint-disable no-unused-vars */
import bazar from "../assets/png/bazzar.jpg";
import mis from "../assets/png/mis.png";
import risepasal from "../assets/png/rise_pasal.png";
import crush from "../assets/png/theGcrush.gif";
import four from "../assets/svg/projects/four.svg";
import one from "../assets/svg/projects/one.svg";
import two from "../assets/svg/projects/two.svg";

//
export const projectsData = [
  {
    id: 0,
    projectName: "theGcrush",
    projectDesc:
      "Candy crush like game made with flutter web. This is just a demonstration what can be done with flutter.",
    tags: ["flutter", "dart"],
    code: "",
    demo: "https://thegcrush.netlify.app",
    image: crush,
    underDevelopment: false,
  },

  // {
  //   id: 2,
  //   projectName: "OMDemand",
  //   projectDesc:
  //     "With OmDemand you can listen, download and share all audios wherever and whenever you want. Every day we update new contents. ",
  //   tags: ["flutter", "nodejs"],
  //   code: "",
  //   demo: "https://apps.apple.com/np/app/omdemand/id1462278680",
  //   image: omdemand,
  //   underDevelopment: false,
  // },
  // {
  //   id: 3,
  //   projectName: "Mantra FM",
  //   projectDesc:
  //     "With MantraFM you can listen, download and share all audios wherever and whenever you want. Every day we update new contents. ",
  //   tags: ["flutter", "wordpress"],
  //   code: "",
  //   demo: "https://apps.apple.com/np/app/omdemand/id1462278680",
  //   image: omdemand,
  //   underDevelopment: false,
  // },
  // {
  //  id: 3,
  //   projectName: "Framez App",
  //   projectDesc:
  //     "",
  //   tags: ["flutter", "wordpress"],
  //   code: "",
  //   demo: "",
  //   image: omdemand,
  //   underDevelopment: false,
  // },
  // {
  //  id: 3,
  //   projectName: "Caunsel",
  //   projectDesc:
  //     "",
  //   tags: ["flutter", "wordpress"],
  //   code: "",
  //   demo: "",
  //   image: omdemand,
  //   underDevelopment: false,
  // },
  // {
  //  id: 3,
  //   projectName: "OOT live",
  //   projectDesc:
  //     "",
  //   tags: ["flutter", "wordpress"],
  //   code: "",
  //   demo: "",
  //   image: omdemand,
  //   underDevelopment: false,
  // },
  // {
  //  id: 3,
  //   projectName: "Audio Ghar",
  //   projectDesc:
  //     "",
  //   tags: ["flutter", "wordpress"],
  //   code: "",
  //   demo: "",
  //   image: omdemand,
  //   underDevelopment: false,
  // },
  // {
  //  id: 3,
  //   projectName: "Blend",
  //   projectDesc:
  //     "",
  //   tags: ["flutter", "wordpress"],
  //   code: "",
  //   demo: "",
  //   image: omdemand,
  //   underDevelopment: false,
  // },
  // {
  //   id: 3,
  //   projectName: "Enabimnio",
  //   projectDesc:
  //     "Enabimnio is an emotional intelligence and mental hygiene system that helps you reprogram and optimize your beliefs by simply reading out loud.",
  //   tags: ["Flutter", "Dart", "Nodejs"],
  //   code: "",
  //   demo: "https://www.enabimnio.com/",
  //   image: enabimnio,
  //   underDevelopment: false,
  // },

  {
    id: 4,
    projectName: "MIS",
    projectDesc:
      "This project is a school management system consisting of roles student, teacher, parent and admin.",
    tags: ["Flutter", "Php", "React.js"],
    code: "",
    demo: "https://play.google.com/store/apps/details?id=com.an4soft.excelschool",
    image: mis,
    underDevelopment: false,
  },
  {
    id: 5,
    projectName: "E-commerce appliaction",
    projectDesc:
      "Single/ Multivendor e-commerce application with payment integration.",
    tags: ["Flutter", "Dart", "Django"],
    code: "",
    demo: "https://play.google.com/store/apps/details?id=com.demo.bazzar",
    image: bazar,
    underDevelopment: false,
  },
  {
    id: 6,
    projectName: "BS Progress",
    projectDesc:
      "BS Progress is a twitter bot that automatically tweets the progress of Nepali Year Bikram Sambat",
    tags: ["python", "tweepy"],
    code: "",
    demo: "https://twitter.com/bs_progress_",
    image:
      "https://pbs.twimg.com/profile_images/1451222408615002114/IVxWefLw_400x400.jpg",
    underDevelopment: false,
  },
  {
    id: 7,
    projectName: "Deru",
    projectDesc:
      "This project aims to connect doctors and patients wirelessly. It was very useful app back 2020 when pandemic just arrived",
    tags: ["Flutter", "Dart", "Php"],
    code: "",
    demo: "https://play.google.com/store/apps/details?id=com.doctor.app962697",
    image: one,
    underDevelopment: false,
  },
  {
    id: 8,
    projectName: "Rise Pasal",
    projectDesc: "A Simple E-commerce application",
    tags: ["Flutter", "Firebase"],
    code: "",
    demo: "https://play.google.com/store/apps/details?id=com.allinoneshop.risepasal",
    image: risepasal,
    underDevelopment: false,
  },

  {
    id: 10,
    projectName: "Nepolive",
    projectDesc:
      "A social media platform where users can go live, chat and video call with friends, share posts and have membership levels too. This project got inspiration from tiktok.",
    tags: ["Flutter", "Dart", "Php"],
    code: "",
    demo: "",
    image: two,
    underDevelopment: true,
  },
  {
    id: 11,
    projectName: "Seat Plan Generation System",
    projectDesc:
      "Specialized web application which generates seat plan of exam hall following TU guidelines.",
    tags: ["React.js", "Php"],
    code: "",
    demo: "https://seat-planner.netlify.app/",
    image: four,
    underDevelopment: false,
  },
];
